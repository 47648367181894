<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">

<!-- <div class="me-auto">
  CPS Admin

</div> -->

    <!-- Toggler -->
    <button class="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- <a class="navbar-brand me-auto" href="./index.html">
      <img src="./assets/img/logo.svg" alt="..." class="navbar-brand-img">
    </a> -->

    <!-- Brand -->
    <!-- <a class="navbar-brand order-lg-first text-center ml-5" routerLink="/">
      <img src="../assets/img/logo.svg" class="navbar-brand-img"><br />
      <b>CPS Admin</b><span class="badge rounded-pill bg-primary">{{ version }}</span>
    </a> -->

    <a href="/#/" class="navbar-brand me-auto">
      <b class="border-black">CPS Admin</b>&nbsp;<span class="badge badge-soft-primary border-black mr-2">{{ version }}</span><span class="badge badge-soft-primary border-black mr-2">{{ empresa }}</span>
    </a>

    <div class="navbar-user">
      <!-- Dropdown -->
      <!--  *ngIf="false" Desabilitado até haver necessidade -->
      <div *ngIf="false" class="dropdown mr-4 d-none d-md-flex">

        <!-- Toggle -->
        <a href="#" class="navbar-user-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="icon active">
            <i class="fe fe-bell"></i>
          </span>
        </a>

        <!-- Menu -->
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-card">
          <div class="card-header">

            <!-- Title -->
            <h5 class="card-header-title">
              Notifications
            </h5>

            <!-- Link -->
            <a href="#!" class="small">
              View all
            </a>

          </div> <!-- / .card-header -->
          <div class="card-body">

            <!-- List group -->
            <div class="list-group list-group-flush list-group-activity my-n3">
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Dianna Smiley</strong> shared your post with Ab Hadley, Adolfo Hess,
                      and 3 others.
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Ab Hadley</strong> reacted to your post with a 😍
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Adolfo Hess</strong> commented
                      <blockquote class="mb-0">
                        “I don’t think this really makes sense to do without approval from Johnathan
                        since he’s the one...”
                      </blockquote>
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-4.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small"><strong>Daniela Dewitt</strong> subscribed to you.</div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Miyah Myles</strong> shared your post with Ryu Duke, Glen Rouse, and 3 others.
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-6.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Ryu Duke</strong> reacted to your post with a 😍
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-7.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small">
                      <strong>Glen Rouse</strong> commented
                      <blockquote class="mb-0">
                        “I don’t think this really makes sense to do without approval from Johnathan
                        since he’s the one...”
                      </blockquote>
                    </div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
              <a class="list-group-item text-reset" href="#!">
                <div class="row">
                  <div class="col-auto">

                    <!-- Avatar -->
                    <div class="avatar avatar-sm">
                      <img src="./assets/img/avatars/profiles/avatar-8.jpg" alt="..." class="avatar-img rounded-circle" />
                    </div>

                  </div>
                  <div class="col ml-n2">

                    <!-- Content -->
                    <div class="small"><strong>Grace Gross</strong> subscribed to you.</div>

                    <!-- Time -->
                    <small class="text-muted">
                      2m ago
                    </small>

                  </div>
                </div> <!-- / .row -->
              </a>
            </div>
          </div>
        </div> <!-- / .dropdown-menu -->
      </div>

      <a (click)="alternateTheme()" class="mt-2 mr-2" role="button" ngbTooltip="{{labelChangeTheme}}">
        <span class="icon active">
          <i class="fe fe-{{iconTheme}}"></i>
        </span>
      </a>

      <a (click)="abrirChamado()" class=" mr-2" role="button" ngbTooltip="Abrir Chamado">
        <span class="icon">
          <i class="fa fa-bullhorn"></i>
        </span>
      </a>

      <!-- Dropdown -->
      <div class="dropdown">

        <!-- Toggle -->
        <!-- <a href="#" class="avatar avatar-sm avatar-online dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." class="avatar-img rounded-circle" />
        </a> -->

        <a href="#" class="ml-2 dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ user?.name ? user?.name : user?.username }}</a>

        <!-- Menu -->
        <div class="dropdown-menu dropdown-menu-right">
          <a (click)="salvaTema('light')" class="dropdown-item pointer">Tema Claro</a>
          <a (click)="salvaTema('dark')" class="dropdown-item pointer">Tema Escuro</a>
          <hr class="dropdown-divider" />
          <a (click)="logout()" class="dropdown-item pointer">Logout</a>
        </div>

      </div>
    </div>

    <!-- Collapse -->
    <div class="collapse navbar-collapse me-lg-auto order-lg-first" id="navbar">
      <dynamic-menu></dynamic-menu>
    </div>

  </div> <!-- / .container -->
</nav>

<div class="modal modalChamado fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-card card">
              <div class="card-header">

                  <!-- Title -->
                  <h4 class="card-header-title">Abrir Chamado</h4>

                  <!-- Close -->
                  <button class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

              </div>
              <div class="card-body">
                  <form>
                      <div class="row col-12 mb-2">
                          <div class="col-12">
                              <small style="color: orange;">
                                Este formulário é destinado para o envio de reclamações, sugestões ou informações relacionadas aos sistemas que você utiliza.<br />
                                Utilize este canal para nos ajudar a melhorar nossos serviços ou informar sobre problemas e dúvidas. <br />
                                Nossa equipe analisará sua mensagem e responderá o mais breve possível. Agradecemos por sua colaboração!
                              </small>
                          </div>
                      </div>

                      <div class="row col-12 mb-2">
                          <div class="col-12">
                              <label class="form-check-label" for="departamento">Departamento<small style="color: red;">*</small></label>
                              <select class="float-right custom-select mb-2" id="departamento" name="departamento" [(ngModel)]="model_chamado.departamento" (change)="alteraDepartamento($event)">
                                <option value="">- Selecione o Departamento... -</option>
                                <option *ngFor="let item of opcoesDepartamento" [value]="item.valor">
                                    {{ item.nome }}
                                </option>
                            </select>
                          </div>
                      </div>

                      <div class="row col-12 mb-2">
                          <div class="col-12 col-md-6">
                              <label class="form-check-label" for="nome">Nome<small style="color: red;">*</small></label><br />
                              <input style="width: 100%;" [disabled]="!abreFormularioChamado" type="text" name="nome" id="nome"
                                  value="{{model_chamado.nome}}" [(ngModel)]="model_chamado.nome">
                          </div>
                          <div class="col-12 col-md-6">
                              <label class="form-check-label" for="email">Email</label><br />
                              <input style="width: 100%;" [disabled]="!abreFormularioChamado" type="text" name="email" id="email"
                                  value="{{model_chamado.email}}" [(ngModel)]="model_chamado.email">
                          </div>
                      </div>

                      <div class="row col-12 mb-2">
                          <div class="col-12 col-md-12">
                            <label class="form-check-label" for="assunto">Assunto<small style="color: red;">*</small></label><br />
                            <input style="width: 100%;" [disabled]="!abreFormularioChamado" type="text" name="assunto" id="assunto"
                                value="model_chamado.assunto" [(ngModel)]="model_chamado.assunto">
                        </div>
                      </div>

                      <div class="row col-12 mb-2">
                        <div class="col-12">
                            <label class="form-check-label" for="mensagem">Mensagem<small style="color: red;">*</small></label><br />
                            <textarea
                                style="width: 100%;"
                                [disabled]="!abreFormularioChamado"
                                name="mensagem"
                                id="mensagem"
                                rows="4"
                                [(ngModel)]="model_chamado.mensagem"></textarea>
                        </div>
                    </div>
                  </form>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-secondary" data-dismiss="modal" [disabled]="loading">Cancelar</button>
                  <button *isInRole="['cli_ativaronu']" (click)="EnviarChamado()" class="btn btn-primary" [class.sending]="loading"> Enviar</button>
              </div>
          </div>
      </div>
  </div>
</div>